import React from 'react';

const Footer = ({ data }) => {
  const networks = data.social.map(network => (
    <li key={network.name}>
      <a href={network.url} target="_blank">
        <i className={network.className} />
      </a>
    </li>
  ));

  return (
    <footer>
      <div className="row">
        <div className="twelve columns">
          <ul className="social-links">{networks}</ul>

          <ul className="copyright">
            <li>
              Made by{' '}
              <a title="ESIREI" href="http://esirei.com/">
                ESIREI
              </a>
            </li>
          </ul>
        </div>
        <div id="go-top">
          <a className="smoothscroll" title="Back to Top" href="#home">
            <i className="icon-up-open" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
