import React from "react";
import TypeWriter from "react-typewriter";

const Header = ({ data }) => {
  const name = data.name;
  const occupation = data.occupation;
  const description = data.description;
  // const city = data.address.city;
  const state = data.address.state;
  const country = data.address.country;
  const networks = data.social.filter(s => s.show).map(network => (
    <li key={network.name}>
      <a href={network.url} target="_blank">
        <i className={network.className} />
      </a>
    </li>
  ));

  return (
    <header id="home">
      <div style={{ position: 'absolute', zIndex: 1, backgroundColor: 'black', opacity: '0.6', left: 0, top: 0, right: 0, bottom: 0 }} />
      <nav id="nav-wrap">
        <a className="mobile-btn" href="#nav-wrap" title="Show navigation">
          Show navigation
        </a>
        <a className="mobile-btn" href="#home" title="Hide navigation">
          Hide navigation
        </a>

        <ul id="nav" className="nav">
          <li className="current">
            <a className="smoothscroll" href="#home">
              Home
            </a>
          </li>
          <li>
            <a className="smoothscroll" href="#about">
              About
            </a>
          </li>
          <li>
            <a className="smoothscroll" href="#resume">
              Resume
            </a>
          </li>
          <li>
            <a className="smoothscroll" href="#portfolio">
              Works
            </a>
          </li>
          <li>
            <a className="smoothscroll" href="#testimonials">
              Testimonials
            </a>
          </li>
          <li>
            <a className="smoothscroll" href="#contact">
              Contact
            </a>
          </li>
        </ul>
      </nav>

      <div className="row banner" style={{ position: 'relative', zIndex: 1 }}>
        <div className="banner-text">
          <h1 className="responsive-headline">
            <TypeWriter typing={0.5}>{name ? `I'm ${name}.` : null}</TypeWriter>
          </h1>
          <h3>
            Based in {state}, {country}. <span>{occupation}</span>. {description}.
          </h3>
          <hr />
          <ul className="social">{networks}</ul>
        </div>
      </div>

      <p className="scrolldown" style={{ position: 'relative', zIndex: 1 }}>
        <a className="smoothscroll" href="#about">
          <i className="icon-down-circle" />
        </a>
      </p>
    </header>
  );
};

export default Header;
